.set-password-email {
   display: flex;
   font-family: SFProDisplay-Semibold;
   font-size: 20px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.6;
   letter-spacing: -0.14px;
   color: #000;
}
.m-t-26 {
   margin-top:26px!important;
}
@media only screen and (max-width: 767px) {
  .disclaimer-set-password span {
   font-size:13px;
   }
}
.pd-16 {
   padding-top:20px!important;
}
.set-password {
label+.Mui-error {
  margin-top: 30px!important;
}
}
@media only screen and (min-width:2000px)
{
 .form-container-margin-top45 {
      margin-top: 45px !important;
   }
}